<template>
  <div class="made-with-love d-flex flex-row align-items-center flex-nowrap">
    <span
      >Made with
      <i class="fas fa-heart fa-fw c-coral-fixed"></i>
      by</span
    >
    <a :href="config.homepageURL" target="_blank">
      <img
        class="ms-md-1 logo-image-fixed-footer"
        :src="config['studio-footer-logo']"
        :alt="config.applicationName"
      />
    </a>
  </div>
</template>

<script lang="ts">
import { getConfig } from '@/utils/configuration';

export default {
  name: 'HMMadeBy',
  setup() {
    const url = useRequestURL();

    return {
      config: getConfig(url),
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.logo-image-fixed-footer {
  max-height: 14px;
  margin-top: -2px;
  @include media-breakpoint-down(md) {
    max-height: 6px;
    margin-left: 4px;
  }
}
</style>
